@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Roboto:wght@400;500&display=swap');

:root {
  --light-shade: #F4FFF8;
  --light-accent: #7C8C73;
  --main-brand-color: #3A7D8C; /* #8BAAAD */
  --dark-accent: #1C3738;  /* #382c1c */
  --dark-shade: #000F08;
  --white: #FFFFFF;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  color: var(--dark-shade);
  background-color: var(--light-shade);
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 1rem;
  color: var(--dark-accent);
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

p {
  margin-bottom: 1rem;
}

a {
  color: var(--main-brand-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--dark-accent);
}

button {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  html {
    font-size: 14px;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.75rem;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in;
}

/* Accessibility */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--light-shade);
}

::-webkit-scrollbar-thumb {
  background: var(--main-brand-color);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--dark-accent);
}

/* Focus styles for accessibility */
:focus {
  outline: 2px solid var(--main-brand-color);
  outline-offset: 2px;
}

/* Print styles */
@media print {
  body {
    color: #000;
    background: #fff;
  }

  a {
    color: #000;
    text-decoration: underline;
  }

  .no-print {
    display: none;
  }
}